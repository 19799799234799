import { Autocomplete, CircularProgress, FormControl, FormHelperText, TextField } from "@mui/material";
import { AppFormAutoCompleteProps } from "./AppFormProps";
import { ARFormSection } from "@/interfaces";
import { Controller } from "react-hook-form";
import { withARAwareReadOnly } from "@/components/hocs";

export const AppFormAutoComplete = ({
  id,
  name,
  control,
  label,
  isRequired,
  options,
  error,
  size,
  loading,
  defaultValue,
  autoSelect,
  autoComplete,
  onInputChange,
  onChange: onChangeOverride,
  "data-testid": dataTestId,
  disabled,
  readOnly,
  isOptionEqualToValue
}: AppFormAutoCompleteProps) => {
  return (
    <FormControl fullWidth size={size}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <Autocomplete
            autoSelect={autoSelect}
            size={size}
            id={id}
            defaultValue={defaultValue}
            data-testid={dataTestId}
            options={options}
            getOptionLabel={(option) => option?.value ?? ""}
            loading={loading}
            autoComplete={autoComplete}
            value={options.find((it) => it.id === value)}
            onChange={(_e, newValue, reason) => {
              onChangeOverride?.(_e, newValue, reason);
              onChange(newValue?.id);
            }}
            onInputChange={onInputChange}
            disabled={disabled}
            readOnly={readOnly}
            isOptionEqualToValue={isOptionEqualToValue}
            renderInput={(params) => (
              <TextField
                required={isRequired}
                error={!!error}
                {...params}
                label={label}
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }}
              />
            )}
          />
        )}
      />
      <FormHelperText error sx={{ marginBottom: 1 }}>
        {error?.message ? error?.message : " "}
      </FormHelperText>
    </FormControl>
  );
};

export const ARAwareReadOnlyAppFormAutoComplete = withARAwareReadOnly(ARFormSection.BasicForm, AppFormAutoComplete);
