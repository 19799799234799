import { createRoot } from "react-dom/client";

import { MULTI_TENANCY, TENANCIES } from "./constants";
import { Root } from "./Root";
import "./index.css";

async function enableMocking() {
  if (import.meta.env.REACT_APP_ENABLE_MSW !== "true") {
    return;
  }

  const { worker } = await import("@/mocks/browser");
  return worker.start();
}

function setTenancy() {
  const url = new URL(window.location.href);
  const tenancy = url.searchParams.get(MULTI_TENANCY.PARAMETER);
  if (tenancy) {
    window.localStorage.setItem(MULTI_TENANCY.STORAGE, getValidTenancy(tenancy));
  }
}

function getValidTenancy(tenancy: string | null): string {
  if (tenancy) {
    const tenancyUpperCase = tenancy.toUpperCase();

    if (TENANCIES.includes(tenancyUpperCase)) {
      return tenancyUpperCase;
    }
  }

  return MULTI_TENANCY.DEFAULT;
}

const container = document.getElementById("root")!;

enableMocking().then(() => {
  setTenancy();
  const root = createRoot(container);
  root.render(<Root />);
});
