import { IOption } from "@/interfaces";

export const MUI_LICENSE_KEY =
  "6810a565b31992f2b422403db8b96c64Tz0xMDcxODEsRT0xNzgwMDk5MjAwMDAwLFM9cHJlbWl1bSxMTT1zdWJzY3JpcHRpb24sUFY9UTMtMjAyNCxLVj0y";

export const TITLE = {
  AppTitle: "LAMS",
  AdminTitle: "LAMS Administrator"
};

export const FILE_NAMES = {
  APP_BAR: "RT_2022_Monogram_Red-White_RGB.png",
  HOME_BG: "bg.png",
  EMPTY_TRACK_COMMENT_BG: "undraw_add_tasks.svg",
  COMPANY_LOGO: "RT_Logo.png",
  PERMIT_Map: "Interactive.png"
};

export const IS_TEST_MODE = import.meta.env.MODE === "test";

export const TENANCIES = ["RTA", "RTIO"];

export const MULTI_TENANCY = {
  HEADER: "x-tenancy",
  PARAMETER: "tenancy",
  STORAGE: "tenancy",
  DEFAULT: "RTIO"
};

export const EXTEND_BY_OPTIONS: IOption[] = [
  {
    id: "6",
    value: "6 months"
  },
  {
    id: "12",
    value: "1 year"
  },
  {
    id: "24",
    value: "2 year"
  },
  {
    id: "36",
    value: "3 year"
  },
  {
    id: "48",
    value: "4 year"
  },
  {
    id: "60",
    value: "5 year"
  }
];

export const ADDITIONAL_HEADER = {
  CLIENT_START_DATE: "x-client-start-date"
};
