import { createRef, useCallback, useEffect, useMemo } from "react";
import { RichTextEditor, RichTextEditorRef } from "@/components/rich-text-editor";
import { AUTH_COMMENT_CONTENT_REGEX } from "@/constants";
import { ClearingAllocation, CommentValue, IOption, PermitAuthorisationComment } from "@/interfaces";
import { formatDate, getAuthCommentFormFieldName, getFormLayoutFromPermitContent } from "@/utils";
import { Grid, Stack, Typography } from "@mui/material";

interface PermitDisciplineProps {
  disciplineName: string;
  disciplineAuthorisedByName: string;
  disciplineAuthorisedDate: Date;
  authorisationComments: PermitAuthorisationComment[];
}

export function PermitDiscipline({
  disciplineName,
  disciplineAuthorisedByName,
  disciplineAuthorisedDate,
  authorisationComments
}: PermitDisciplineProps) {
  const editorRef = createRef<RichTextEditorRef>();

  const getChipArrayValue = useCallback((commentValue: string): string => {
    const valueInJson = commentValue ? (JSON.parse(commentValue) as IOption[]) : [];
    return valueInJson.map((a) => a.value).join(", ");
  }, []);

  const getDateValue = useCallback((commentValue: string): string => {
    return formatDate(commentValue) ?? "";
  }, []);

  const getNVCPTableValue = useCallback((commentValue: string): string => {
    const valuesInJson = commentValue ? JSON.parse(commentValue) : [];
    const totalNumberOfValuesFromZeroIndex = valuesInJson.length - 1;

    const values: string[] = [];

    valuesInJson.map((value: ClearingAllocation, index: number) => {
      const formattedDate = formatDate(value.noClearingAfterDate);
      values.push(`
        <ul>
          <li>
            <p>NVCP: ${value.referenceNo}</p>
            <p></p>
            <p>Clearing Allocation: ${value.clearingAllocation} ha</p>
            <p>No clearing after: ${formattedDate} </p>
            ${value.restrictedClearingId ? `<p>Restricted Clearing - ${value.restrictedClearing}: ${value.restrictedClearingAllocation} ha</p>` : ""}
            ${value.termsAndConditions ? `<p>${value.termsAndConditions}</p>` : ""}
            ${index !== totalNumberOfValuesFromZeroIndex ? "<p></p>" : ""}
          </li>
        </ul>
      `);
    });

    return values.join("");
  }, []);

  const getEPBCTableValue = useCallback((commentValue: string): string => {
    const valuesInJson = commentValue ? JSON.parse(commentValue) : [];
    const totalNumberOfValuesFromZeroIndex = valuesInJson.length - 1;

    const values: string[] = [];

    valuesInJson.map((value: ClearingAllocation, index: number) => {
      values.push(`
        <ul>
          <li>
            <p>EPBC Reference: ${value.referenceNo}</p>
            <p></p>
            <p>Clearing Allocation: ${value.clearingAllocation} ha</p>
            ${value.restrictedClearingId ? `<p>Restricted Clearing - ${value.restrictedClearingId}: ${value.restrictedClearingAllocation} ha</p>` : ""}
            ${value.termsAndConditions ? `<p>${value.termsAndConditions}</p>` : ""}
            ${index !== totalNumberOfValuesFromZeroIndex ? "<p></p>" : ""}
          </li>
        </ul>
      `);
    });

    return values.join("");
  }, []);

  const getPermitFormatValueByComponentType = useCallback(
    (commentValue: CommentValue) => {
      let formattedAuthCommentValue = "";
      switch (commentValue.componentType) {
        case "chipArray":
          formattedAuthCommentValue = getChipArrayValue(commentValue.value.toString());
          break;
        case "date":
          formattedAuthCommentValue = getDateValue(commentValue.value.toString());
          break;
        case "epbc-table":
          formattedAuthCommentValue = getEPBCTableValue(commentValue.value.toString());
          break;
        case "nvcp-table":
          formattedAuthCommentValue = getNVCPTableValue(commentValue.value.toString());
          break;
        default:
          formattedAuthCommentValue = commentValue.value.toString();
      }
      return formattedAuthCommentValue;
    },
    [getChipArrayValue, getDateValue, getEPBCTableValue, getNVCPTableValue]
  );

  const getChildContentValues = useCallback(
    (authCommentFormValues: object, permitContent: string): string => {
      let childContentValues = getFormLayoutFromPermitContent(permitContent);
      if (JSON.stringify(authCommentFormValues) === "{}") return "";
      Object.entries(authCommentFormValues).forEach(([disciplineAuthCommentValueName, authCommentFormValue]) => {
        childContentValues = childContentValues.replace(
          getAuthCommentFormFieldName(disciplineAuthCommentValueName),
          getPermitFormatValueByComponentType(authCommentFormValue)
        );
      });
      return childContentValues;
    },
    [getPermitFormatValueByComponentType]
  );

  const disciplinePermitComments = useMemo(() => {
    const comments: string[] = [];
    authorisationComments.forEach((authComment) => {
      let permitContent = authComment.permitContent;
      let formCommentValue: string = "";
      Object.entries(authComment.commentValues).forEach(([key, commentValue]) => {
        if (commentValue.componentType === "form") {
          formCommentValue +=
            typeof commentValue.value === "object"
              ? getChildContentValues(commentValue.value, permitContent)
              : commentValue.value;
        }
        permitContent = permitContent.replaceAll(key, getPermitFormatValueByComponentType(commentValue));
      });

      const formLayout = getFormLayoutFromPermitContent(permitContent);
      if (formCommentValue === "") {
        formCommentValue = formLayout;
        formCommentValue.match(AUTH_COMMENT_CONTENT_REGEX)?.map((match) => {
          formCommentValue = formCommentValue.replaceAll(match, "");
        });
      }
      permitContent = permitContent.replaceAll(`**${formLayout}**`, formCommentValue);

      Object.entries(authComment.defaultValues).forEach(([key, defaultValue]) => {
        permitContent = permitContent.replaceAll(key, defaultValue);
      });

      comments.push(`<li>${permitContent}</li>`);
    });

    return `<ol>${comments.join("")}</ol>`;
  }, [authorisationComments, getChildContentValues, getPermitFormatValueByComponentType]);

  useEffect(() => {
    editorRef.current?.reset(disciplinePermitComments);
  }, [disciplinePermitComments, editorRef]);

  return (
    <Grid container xs={12} rowGap="1rem">
      <Grid item container xs={12} alignItems="center" justifyContent="space-between">
        <Grid item xs={4}>
          <Typography fontSize="2rem">{disciplineName}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Stack>
            <Typography fontWeight={100}>SME</Typography>
            <Typography fontSize="1.5rem">{disciplineAuthorisedByName}</Typography>
          </Stack>
        </Grid>
        <Grid item xs={3}>
          <Stack>
            <Typography fontWeight={100}>Authorised Date</Typography>
            <Typography fontSize="1.5rem">{formatDate(disciplineAuthorisedDate)}</Typography>
          </Stack>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography fontSize={16} fontWeight={100}>
          Authorised Activities
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <RichTextEditor
          readOnly={true}
          hideMenuBar={true}
          ref={editorRef}
          defaultValue={disciplinePermitComments}
          data-testid="auth-comments-editor"
          className="permit-auth-comment"
        />
      </Grid>
    </Grid>
  );
}
