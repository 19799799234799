import { StrictMode } from "react";
import { MsalAuthenticationTemplate, MsalProvider } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

import { msalInstance, msalScopes } from "./authentication";
import { ApplicationInsightsProvider } from "./app-insights";
import { App } from "./App";

export function Root() {
  return (
    <StrictMode>
      <MsalProvider instance={msalInstance}>
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect} authenticationRequest={msalScopes}>
          <ApplicationInsightsProvider>
            <App />
          </ApplicationInsightsProvider>
        </MsalAuthenticationTemplate>
      </MsalProvider>
    </StrictMode>
  );
}
